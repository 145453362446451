<template>
  <sign-page
    title-text="我的请假"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    table-size="large"
    :tableActions="tableActions"
    @tableAction="tableAction"
    :tableActionsFixed="true"
    :need-data-file="true">
    <fm-modal title="请假条详细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="{}" :id="chooseData.askLeaveApplyId"></detail>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request,
  statusManageRequest,
  askLeaveApplyRequest
} from '@/api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'

export default {
  components: {
    detail
  },
  created () {
    if (this.$authFunsProxy.detail) {
      this.loadConfig()
    }
  },
  methods: {
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'askLeaveApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      let d = await askLeaveApplyRequest.getType()
      this.applyTypeList = d.map(v => {
        return {
          key: String(v.id),
          label: v.label
        }
      })
      this.config = {}
      this.applyTypeList.forEach(a => {
        this.config[a.key] = c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === a.key))
      })
      this.config['null'] = c.statusSwitchList.filter(v => v.isHost && !v.tag)
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '假条明细'
      },
      {
        key: 'data_file',
        label: '附件'
      }]
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        field: 'id'
      },
      {
        title: '请假类型',
        sort: true,
        field: 'type',
        filters: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {
          return {
            label: v,
            value: v
          }
        })
      },
      {
        title: '申请时间',
        sort: true,
        filterRange: true,
        dataType: Date,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        filterRange: true,
        dataType: Date,
        sort: true,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        sort: true,
        field: 'endTime',
        filterRange: true,
        dataType: Date,
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        filterRange: true,
        dataType: Number,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson',
        render: (h, row) => {
          return h(TextCell, {
            props: {
              value: row.reson
            }
          })
        }
      },
      {
        title: '状态',
        sort: true,
        field: 'status'
      }]
      return data
    }
  },
  data () {
    return {
      request: {
        get: request.getMy
      },
      statusMap: {},
      chooseData: null,
      modal: false
    }
  }
}
</script>
